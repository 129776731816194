import React from 'react';
import ServiceImage from '../../assets/img/service/ui-ux.jpg';
import TeamImage from '../../assets/img/team/jigar-tewar.jpg';
import JigarTewar from '../team/jigar-tewar';
function UIUXDesign() {
  return (
    <section className="space-extra-bottom">
    <div className="container">
        <div className="row">
            <div className="col-xxl-12 col-lg-12">

                <div className="page-single">
                    <div className="page-img">
                        <img src={ServiceImage} alt="Service Image"/>
                    </div>
                    <div className="row gy-4 align-items-top">
                    <div className="page-content col-md-9">
                    <h2 className="h3 page-title">UI/UX Design</h2>
                            <p>At CloudBlaze Tech, we believe that user experience is paramount. Our UI/UX design services are dedicated to creating intuitive, engaging, and user-friendly interfaces that enhance user satisfaction and drive adoption.</p>
                            
                            <p className="mb-30">We employ a user-centric design approach, utilizing wireframes, prototypes, and user testing to ensure that our designs meet the highest standards of usability and aesthetic appeal. Our goal is to create digital experiences that are not only functional but also delightful.</p>

                        
                    </div>
                        
                        
                        <div className="col-md-3">
                                    <img className="w-100 rounded-3" src={TeamImage} alt="project"/>
                                    <div className="th-social" style={{ paddingTop: '10px', textAlign: 'center' }}>
                                    <a target="_blank" href="https://www.linkedin.com/in/jigartewar"><i className="fab fa-linkedin-in"></i></a>&nbsp;&nbsp;&nbsp;
                                        <a target="_blank" href="mailto:pritesh.kacchadiya@cloudblaze.tech"><i className="fa-solid fa-envelope"></i></a>&nbsp;&nbsp;&nbsp;
                                            <a href="../jigar-tewar"><i className="fa-solid fa-link"></i></a>
                                    </div>
                                        
                                
                                    
                                </div>
                        
                </div>
                </div>
        </div>
    </div>
</div>
</section>
  );
}


export default UIUXDesign;
