import React from 'react';
import { Link } from 'react-router-dom';
import logo from "../assets/img/CloudBlaze-logo@3x.png"
import Navigation from './navigation';

function Header() {
  return (
    <header className="th-header header-layout2">
        <div className="header-top">
            <div className="container">
                <div className="row justify-content-center justify-content-lg-between align-items-center gy-2">
                    <div className="col-auto d-none d-lg-block">
                        <div className="header-links">
                            <ul>
                                <li><i className="fas fa-map-location"></i>Surat, Gujarat, india</li>
                                <li><i className="fas fa-phone"></i><a href="tel:+919327350853">+91 93273 50853</a></li>
                                <li><i className="fas fa-envelope"></i><a href="mailto:info@cloudblaze.tech">info@cloudblaze.tech</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-auto">
                        <div className="header-social">
                            <span className="social-title">Follow Us On : </span>
                            <a href="https://www.facebook.com/"><i className="fab fa-facebook-f"></i></a>
                            <a href="https://www.twitter.com/"><i className="fab fa-twitter"></i></a>
                            <a href="https://www.linkedin.com/"><i className="fab fa-linkedin-in"></i></a>
                            <a href="https://www.instagram.com/"><i className="fab fa-instagram"></i></a>
                            <a href="https://www.youtube.com/"><i className="fab fa-youtube"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="sticky-wrapper">
            <div className="menu-area">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-auto">
                            <div className="header-logo">
                                <a className="icon-masking" href="index.html"><img src={logo} width="350px" alt="CloudBlaze Tech Solutions"/></a>
                            </div>
                        </div>
                        <div className="col-auto">
                            <nav className="main-menu d-none d-lg-inline-block">
                                <Navigation/>
                            </nav>
                            
                            <div className="header-button">
                                <button type="button" className="th-menu-toggle d-inline-block d-lg-none"><i className="far fa-bars"></i></button>
                            </div>
                           
                        </div>
                        <div className="col-auto d-none d-lg-block">
                            <div className="header-button">
                                
                                <Link to="contact.html" className="th-btn shadow-none">Make An Appointment<i className="fas fa-arrow-right ms-2"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
  );
}

export default Header;
