import React from 'react';
import { Link } from 'react-router-dom';
import ServiceImage from '../../assets/img/service/compliance.jpg';
import TeamImage from '../../assets/img/team/sujeet-katiyar.jpg';
import SujeetKatiyar from '../team/sujeet-katiyar';

function ProductManagement() {
  return (
    <section className="space-extra-bottom">
    <div className="container">
        <div className="row">
            <div className="col-xxl-12 col-lg-12">

                <div className="page-single">
                    <div className="page-img">
                        <img src={ServiceImage} alt="Service Image"/>
                    </div>
                    <div className="row gy-4 align-items-top">
                    <div className="page-content col-md-9">
                        <h2 className="h3 page-title">Compliance</h2>
                        <p>In an era of increasing cyber threats and stringent regulatory requirements, security and compliance are more critical than ever. CloudBlaze Tech provides comprehensive security solutions that protect sensitive data and ensure compliance with industry standards and regulations such as GDPR, HIPAA, and ISO 27001.</p>
                            
                            <p className="mb-30">Our services include risk assessments, security audits, vulnerability testing, and the implementation of advanced security measures to safeguard our clients' digital assets and maintain their trust.</p>

                        
                    </div>
                        
                        
                        <div className="col-md-3">
                                    <img className="w-100 rounded-3" src={TeamImage} alt="project"/>
                                    <div className="th-social" style={{ paddingTop: '10px', textAlign: 'center' }}>
                                    <a target="_blank" href="http://www.linkedin.com/in/sujeetkatiyar/"><i className="fab fa-linkedin-in"></i></a>&nbsp;&nbsp;&nbsp;
                                        <a target="_blank" href="mailto:sujeet.katiyar@cloudblaze.tech"><i className="fa-solid fa-envelope"></i></a>&nbsp;&nbsp;&nbsp;
                                        <Link to="../sujeet-katiyar"><i className="fa-solid fa-link"></i></Link>
                                    </div>
                                        
                                    
                                
                                    
                                </div>
                        
                </div>
                </div>
        </div>
    </div>
</div>
</section>
  );
}


export default ProductManagement;
