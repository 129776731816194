import React from 'react';
import { Link } from 'react-router-dom';

import TeamImage from '../../assets/img/team/sujeet-katiyar.jpg';
import Compliance  from '../services/compliance-page';

function SujeetKatiyar() {
  return (
    <section className="space-extra-bottom">
          <div className="project-inner-box mb-40">
                                <h3 className="box-title">Sujeet Katiyar – Compliance Expert</h3>
                                
                                <div className="row gy-4 align-items-top">
                                    <div className="col-md-3">
                                        <img className="w-100 rounded-3" src={TeamImage} alt="project"/>
                                        <div className="th-social" style={{ paddingTop: '10px', textAlign: 'center' }}>
                                        <a target="_blank" href="http://www.linkedin.com/in/sujeetkatiyar/"><i className="fab fa-linkedin-in"></i></a>&nbsp;&nbsp;&nbsp;
                                        <a target="_blank" href="mailto:sujeet.katiyar@cloudblaze.tech"><i className="fa-solid fa-envelope"></i></a>&nbsp;&nbsp;&nbsp;
                                        <Link to="../compliance"><i className="fa-solid fa-link"></i></Link>
                                    </div>
                                        
                                    
                                        
                                    </div>
                                    <div className="col-md-9">
                                        <div className="checklist">
                                            <ul>
                                                <li>Entrepreneur, Healthcare Regulatory Compliance & Healthcare Technology Professional with Expertise in Digital Healthcare (Telehealth & Telemedicine, mHealth, EHR, Health Information Technology, Healthcare Analytics), ABDM, Digital Personal Data Protection Act, 2023, HIPAA, GDPR, Data Protection, Regulatory Compliance, Preventive Healthcare, Wellness, Indian System of Medicine, MedLegal, eLearning, CSR etc. Sound and working knowledge of Web & Mobile Technologies with AI, ML, IoT, Blockchain, and Cybersecurity.<br/><br/>

                                                Domain Expertise<br/><br/>
                                                • Primary domain expertise in Digital Healthcare, eHealth, mHealth, EMR/EHR, Preventive Healthcare, and Wellness. Secondary domain expertise in eLearning, Education, MedcoLegal, Indian System of Medicine, eCommerce, CSR, etc.<br/><br/>
                                                • In-depth knowledge & exposure of healthcare regulations, standards & guidelines e.g. Digital Personal Data Protection Act, 2023, Telemedicine Practice Guidelines by MCI & NITI Aayog, Health Data Management Policy, National Digital Health Mission, GDPR, HIPAA, FHIR, HL7, DICOM, ICD, DISHA (Digital Information Security in Healthcare. Act), EHR standards 2013, NABH, NABL, ISO 9001, ISO/IEC 27001:2013, ISO 15189_2012, Clinical Establishment Act 2010 etc.<br/><br/>
                                                • Thorough understanding of the Indian Healthcare System (AYUSH) as many projects are done for Indian clients ranging from Corporate Hospitals to medium & small size hospitals & chain clinics, pharmacy management system Government of India’s healthcare establishments including AYUSH, various state Governments, etc.<br/><br/>
                                                • Worked with a number of hospitals and healthcare establishments overseas e.g. US-based chain of clinics, an India-based well-known chain clinic with a global presence, a UK & Ireland-based chain of naturopathy colleges, ISO-certified hospitals, virtual clinic solutions, and telemedicine solutions, etc.<br/><br/>
                                                    
                                                Technical Expertise<br/><br/>
                                                • More than 24 years of experience in software project management, design, development, implementation, and maintenance.<br/><br/>
                                                • Technical expertise in SMAC (Social, Mobile, Analytics, and Cloud) technologies<br/><br/>
                                                • Expertise in Agile, Scrum, Spiral, Waterfall Project Management Methodologies.<br/><br/>
                                                • Extensive experience in object-oriented programming, analysis, and design along with hands-on experience with Cloud Computing, Web Technologies, Mobile Technologies, Client / Server Technology, Component Technology and System programming, etc.<br/><br/>
                                                </li>
                                                
                                                
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

   </section>
    );
}
export default SujeetKatiyar;