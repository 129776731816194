import React from 'react';
import { Link } from 'react-router-dom';

import TeamImage from '../../assets/img/team/sid-arora.jpg';
import ProductManagement  from '../services/product-management-page';

function SidArora() {
  return (
    <section className="space-extra-bottom">
       <div className="project-inner-box mb-40">
                                <h3 className="box-title">Sid Arora - Product Management</h3>
                                
                                <div className="row gy-4 align-items-top">
                                    <div className="col-md-3">
                                        <img className="w-100 rounded-3" src={TeamImage} alt="project"/>
                                        <div className="th-social" style={{ paddingTop: '10px', textAlign: 'center' }}>
                                        <a target="_blank" href="https://www.linkedin.com/in/siddhartharoraisb/"><i className="fab fa-linkedin-in"></i></a>&nbsp;&nbsp;&nbsp;
                                        <a target="_blank" href="mailto:siddharth.arora@cloudblaze.tech"><i className="fa-solid fa-envelope"></i></a>&nbsp;&nbsp;&nbsp;
                                        <Link to="../product-Management"><i className="fa-solid fa-link"></i></Link>
                                    </div>
                                        
                                    
                                        
                                    </div>
                                    <div className="col-md-9">
                                        <div className="checklist">
                                            <ul>
                                                <li>A computer engineer and an MBA by education, Product Manager by experience, and entrepreneur by heart.<br/><br/>

                                                    I have worked as a PM across industries for B2C, B2B and SaaS companies. In my experience, I have created products used by millions of users all over the world.<br/><br/> 

                                                    As a Product leader, I have experience in leading product vision and strategy from ideation to execution.<br/><br/>

                                                    My interest in data analytics, user research, and UX has helped me fuel my passion for creating world-class products. <br/><br/>

                                                

                                                
                                                
                                                </li>
                                                
                                                
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
   </section>
    );
}
export default SidArora;