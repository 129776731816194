// components/Services.jsx
import React from 'react';

function Services() {
  return (
    <section className="space" id="service-sec">
    <div className="container">
            <div className="title-area text-center">
                <span className="sub-title">Consulting Services</span>
                <h2 className="sec-title"></h2>
            </div>
            <div className="row gy-4">
            <div className="row col-md-6 col-xl-4">
                <div className="service-card">
                    <div className="service-card_number">01</div>
                    <div className="shape-icon">
                        <img src="assets/img/icon/service_card_7.svg" alt="Icon"/>
                        <span className="dots"></span>
                    </div>
                    <h3 className="box-title"><a href="#">Software Architecture</a></h3>
                    <p className="service-card_text">Our architectural services focus on creating scalable, resilient, and flexible software architectures that support the long-term objectives of our clients.</p>
                    
                    <a href="Software-Architecture.html" className="th-btn">Read More<i className="fa-regular fa-arrow-right ms-2"></i></a>
                    <div className="bg-shape">
                        <img src="assets/img/bg/service_card_bg.png" alt="bg"/>
                    </div>
                </div>
            </div>
            <div className="col-md-6 col-xl-4">
                <div className="service-card">
                    <div className="service-card_number">02</div>
                    <div className="shape-icon">
                        <img src="assets/img/icon/service_card_8.svg" alt="Icon"/>
                        <span className="dots"></span>
                    </div>
                    
                    <h3 className="box-title"><a href="#">Product Management</a></h3>
                    <p className="service-card_text">Our product management services focus on guiding products from conception through to launch and beyond.</p>
                    
                    
                    <a href="Product-Management.html" className="th-btn">Read More<i className="fa-regular fa-arrow-right ms-2"></i></a>
                    <div className="bg-shape">
                        <img src="assets/img/bg/service_card_bg.png" alt="bg"/>
                    </div>
                </div>
            </div>
            <div className="col-md-6 col-xl-4">
                <div className="service-card">
                    <div className="service-card_number">03</div>
                    <div className="shape-icon">
                        <img src="assets/img/icon/service_card_6.svg" alt="Icon"/>
                        <span className="dots"></span>
                    </div>
                    <h3 className="box-title"><a href="#">AI / ML</a></h3>
                    <p className="service-card_text">CloudBlaze Tech's business analysis services are solves complex business challenges.</p>
                    
                    <a href="ai-ml.html" className="th-btn">Read More<i className="fa-regular fa-arrow-right ms-2"></i></a>
                    <div className="bg-shape">
                        <img src="assets/img/bg/service_card_bg.png" alt="bg"/>
                    </div>
                </div>
            </div>
            <div className="row col-md-6 col-xl-4">
                <div className="service-card">
                    <div className="service-card_number">04</div>
                    <div className="shape-icon">
                        <img src="assets/img/icon/service_card_4.svg" alt="Icon"/>
                        <span className="dots"></span>
                    </div>
                    <h3 className="box-title"><a href="#">Security and Compliance</a></h3>
                    <p className="service-card_text">In an era of increasing cyber threats and stringent regulatory requirements, security and compliance are more critical than ever.</p>
                    
                    
                    <a href="Security-and-Compliance.html" className="th-btn">Read More<i className="fa-regular fa-arrow-right ms-2"></i></a>
                    <div className="bg-shape">
                        <img src="assets/img/bg/service_card_bg.png" alt="bg"/>
                    </div>
                </div>
            </div>
            <div className="col-md-6 col-xl-4">
                <div className="service-card">
                    <div className="service-card_number">05</div>
                    <div className="shape-icon">
                        <img src="assets/img/icon/service_card_2.svg" alt="Icon"/>
                        <span className="dots"></span>
                    </div>
                    <h3 className="box-title"><a href="#">UI/UX Design</a></h3>
                    <p className="service-card_text">Our UI/UX design services are dedicated to creating intuitive, engaging, and user-friendly interfaces that enhance user satisfaction and drive adoption.</p>
                    
                    <a href="UIUX-Design.html" className="th-btn">Read More<i className="fa-regular fa-arrow-right ms-2"></i></a>
                    <div className="bg-shape">
                        <img src="assets/img/bg/service_card_bg.png" alt="bg"/>
                    </div>
                </div>
            </div>
            <div className="col-md-6 col-xl-4">
                <div className="service-card">
                    <div className="service-card_number">06</div>
                    <div className="shape-icon">
                        <img src="assets/img/icon/service_card_9.svg" alt="Icon"/>
                        <span className="dots"></span>
                    </div>
                    <h3 className="box-title"><a href="#">Delivery</a></h3>
                    <p className="service-card_text">CloudBlaze Tech's business analysis services are solves complex business challenges.</p>
                    
                    <a href="delivery.html" className="th-btn">Read More<i className="fa-regular fa-arrow-right ms-2"></i></a>
                    <div className="bg-shape">
                        <img src="assets/img/bg/service_card_bg.png" alt="bg"/>
                    </div>
                </div>
            </div>
            <div className="col-md-6 col-xl-4">
                <div className="service-card">
                    <div className="service-card_number">07</div>
                    <div className="shape-icon">
                        <img src="assets/img/icon/service_card_5.svg" alt="Icon"/>
                        <span className="dots"></span>
                    </div>
                    <h3 className="box-title"><a href="#">DevOps</a></h3>
                    <p className="service-card_text">Our DevOps services aim to bridge the gap between development and operations, fostering a culture of collaboration.</p>
                    
                    <a href="DevOps.html" className="th-btn">Read More<i className="fa-regular fa-arrow-right ms-2"></i></a>
                    <div className="bg-shape">
                        <img src="assets/img/bg/service_card_bg.png" alt="bg"/>
                    </div>
                </div>
            </div>
            <div className="col-md-6 col-xl-4">
                <div className="service-card">
                    <div className="service-card_number">08</div>
                    <div className="shape-icon">
                        <img src="assets/img/icon/service_card_1.svg" alt="Icon"/>
                        <span className="dots"></span>
                    </div>
                    <h3 className="box-title"><a href="#">Software Engineering</a></h3>
                    <p className="service-card_text">CloudBlaze Tech offers comprehensive software engineering services that encompass the entire software development lifecycle.</p>
                    
                    <a href="Software-Engineering.html" className="th-btn">Read More<i className="fa-regular fa-arrow-right ms-2"></i></a>
                    <div className="bg-shape">
                        <img src="assets/img/bg/service_card_bg.png" alt="bg"/>
                    </div>
                </div>
            </div>
            </div>
        </div>
  </section>
  );    
}

export default Services;
