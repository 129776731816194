import React from 'react';
import { Link } from 'react-router-dom';

import TeamImage from '../../assets/img/team/dhaval-pancholi.jpg';
import Devops from '../services/devops-page';

function DhavalPancholi() {
  return (
    <section className="space-extra-bottom">
           <div className="project-inner-box mb-40">
                                <h3 className="box-title">Dhaval Pancholi - DevOps Lead</h3>
                                
                                <div className="row gy-4 align-items-top">
                                    <div className="col-md-3">
                                        <img className="w-100 rounded-3" src={TeamImage} alt="project"/>
                                        <div className="th-social" style={{ paddingTop: '10px', textAlign: 'center' }}>
                                        <a target="_blank" href="https://www.linkedin.com/in/dhavalpancholi/"><i className="fab fa-linkedin-in"></i></a>&nbsp;&nbsp;&nbsp;
                                        <a target="_blank" href="mailto:dhaval.pancholi@cloudblaze.tech"><i className="fa-solid fa-envelope"></i></a>&nbsp;&nbsp;&nbsp;
                                            <Link to="../devops"><i className="fa-solid fa-link"></i></Link>
                                    </div>
                                        
                                    
                                        
                                    </div>
                                    <div className="col-md-9">
                                        <div className="checklist">
                                            <ul>
                                                <li>More than 5 years of DevOps experience with designing and
                                                    implementing processes for application development lifecycle.<br/><br/>
                                                    Overall more than 16 years of IT experience mainly as a Linux
                                                    System administrator with hands-on experience with all stages of
                                                    server system development efforts, including troubleshooting,
                                                    testing and support.<br/><br/>
                                                    Experience of working with containerization of applications in
                                                    microservices based architecture using Docker and Kubernetes<br/><br/>

                                                

                                                
                                                
                                                </li>
                                                
                                                
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
    </section>
    );
}
export default DhavalPancholi