import React from 'react';
import ServiceImage from '../../assets/img/service/devops.jpg';
import TeamImage from '../../assets/img/team/dhaval-pancholi.jpg';
import DhavalPancholi from '../team/dhaval-pancholi';
import { Link } from 'react-router-dom';
function ProductManagement() {
  return (
    <section className="space-extra-bottom">
    <div className="container">
        <div className="row">
            <div className="col-xxl-12 col-lg-12">

                <div className="page-single">
                    <div className="page-img">
                        <img src={ServiceImage} alt="Service Image"/>
                    </div>
                    <div className="row gy-4 align-items-top">
                    <div className="page-content col-md-9">
                    <h2 className="h3 page-title">DevOps</h2>
                            <p>Our DevOps services aim to bridge the gap between development and operations, fostering a culture of collaboration and continuous improvement. By implementing DevOps practices and tools, we help clients achieve faster delivery times, higher quality software, and improved operational efficiency.</p>
                            
                            <p className="mb-30">Our services include continuous integration and continuous delivery (CI/CD), infrastructure as code (IaC), automated testing, and monitoring. We ensure that our clients can quickly adapt to changing market demands and achieve greater agility in their software development processes.</p>

                        
                    </div>
                        
                        
                        <div className="col-md-3">
                                    <img className="w-100 rounded-3" src={TeamImage} alt="project"/>
                                    <div className="th-social" style={{ paddingTop: '10px', textAlign: 'center' }}>
                                    <a target="_blank" href="https://www.linkedin.com/in/dhavalpancholi/"><i className="fab fa-linkedin-in"></i></a>&nbsp;&nbsp;&nbsp;
                                        <a target="_blank" href="mailto:dhaval.pancholi@cloudblaze.tech"><i className="fa-solid fa-envelope"></i></a>&nbsp;&nbsp;&nbsp;
                                            <Link to="../dhaval-pancholi"><i className="fa-solid fa-link"></i></Link>
                                    </div>
                                        
                                    
                                
                                    
                                </div>
                        
                </div>
                </div>
        </div>
    </div>
</div>
</section>
  );
}


export default ProductManagement;
