import React from 'react';
import { Link } from 'react-router-dom';

import TeamImage from '../../assets/img/team/dinesh-o-bareja.jpg';
import Security  from '../services/security-page';

function DineshOBareja() {
  return (
    <section className="space-extra-bottom">
                              <div className="project-inner-box mb-40">
                                <h3 className="box-title">Dinesh Bareja – CyberSecurity Expert</h3>
                                
                                <div className="row gy-4 align-items-top">
                                    <div className="col-md-3">
                                        <img className="w-100 rounded-3" src={TeamImage} alt="project"/>
                                        <div className="th-social" style={{ paddingTop: '10px', textAlign: 'center' }}>
                                        <a target="_blank" href="http://www.linkedin.com/in/dineshbareja/"><i className="fab fa-linkedin-in"></i></a>&nbsp;&nbsp;&nbsp;
                                        <a target="_blank" href="mailto:dinesh.bareja@cloudblaze.tech"><i className="fa-solid fa-envelope"></i></a>&nbsp;&nbsp;&nbsp;
                                            <Link to="../security"><i className="fa-solid fa-link"></i></Link>
                                    </div>
                                        
                                    
                                        
                                    </div>
                                    <div className="col-md-9">
                                        <div className="checklist">
                                            <ul>
                                                <li>As the COO of Open Security Alliance LLP, I lead a team of IS, IT, and business professionals who provide practical and visionary solutions and advisory services for various clients' security needs.<br/><br/>

                                                With over two decades experience in this role, I have successfully delivered projects in information and data asset management and security architecture design, security posture assessment and optimization, product-led solutions, enterprise software asset management, data governance and classification, threat and risk intelligence, and awareness and training.<br/><br/>

                                                We are always ready to provide services for ISMS ISO27001, RBI Compliance, IRDA, SEBI, BCMS, Crisis Mgt, Incident response, managed security services and of course VAPT, AppSec etc<br/><br/>

                                                My mission is to help clients address their security challenges by co-creating solutions that align with their business strategy, needs, and environment. I work with enterprises of all sizes, governments, law enforcement agencies, and public entities across different sectors and geographies. I have also established and grown the cybersecurity practice at OSA, offering GRC and technical services such as VAPT, AppSec, SOC, and MSSP. Additionally, I serve as an advisor for several organizations in the fields of cyber peace, digital forensics, critical infrastructure protection, and cyberwar. I am a certified CISA, CISM, and IRCA Internal Auditor, and a Microsoft MVP in Enterprise Security.<br/><br/>
                                                    
                                                Highly experienced professional working in Cybersecurity / Information Security. Excel in providing practical and pragmatic advice and consulting services without any hype. Can help you in starting your security journey or in course correction by assessing your current state and helping you re-engineer your IS/CS posture.

                                                
                                                </li>
                                                
                                                
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

   </section>
    );
}
export default DineshOBareja;