import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';



import './assets/css/bootstrap.min.css';
import './assets/css/fontawesome.min.css';
import './assets/css/magnific-popup.min.css';
import './assets/css/swiper-bundle.min.css';
import './assets/css/style.css';



import 'magnific-popup';
import Isotope from 'isotope-layout';

import logo from './logo.svg';
import './App.css';



import Header from './components/header';
import Footer from './components/footer';
import MobileMenu from './components/mobile-menu';



import AboutPage from './pages/about-page';
import HomePage from './pages/home-page';



import SoftwareArchitecture from './pages/services/software-architecture-page';
import ProductManagement from './pages/services/product-management-page';
import AIML from './pages/services/ai-ml-page';
import Compliance from './pages/services/compliance-page';
import Security from './pages/services/security-page';
import DeliveryManagement from './pages/services/delivery-management-page';
import UIUXDesign from './pages/services/ui-ux-design-page';
import DevOps from './pages/services/devops-page';
import ProjectManagement from './pages/services/project-management-page';


import SagarKapadia from './pages/team/sagar-kapadia';
import DhavalPancholi from './pages/team/dhaval-pancholi';
import DineshOBareja from './pages/team/dinesh-o-bareja';
import JaydeepDosi from './pages/team/jaydeep-dosi';
import JigarTewar from './pages/team/jigar-tewar';
import PriteshKacchadiya from './pages/team/pritesh-kacchadiya';
import PurvikRana from './pages/team/purvik-rana';
import SidArora from './pages/team/sid-arora';
import SujeetKatiyar from './pages/team/sujeet-katiyar';




function App() {

   const iso = new Isotope('.grid', {
     itemSelector: '.grid-item',
   });

  return (
    <Router>
    <div className="App">
      <MobileMenu />
      <Header />
      
      <Routes>
      <Route path="/" element={<HomePage />}/>
        <Route path="/home" element={<HomePage />} />
        <Route path="/about-page" element={<AboutPage />} />  
        <Route path="/software-architecture" element={<SoftwareArchitecture />} />  
        <Route path="/product-management" element={<ProductManagement />} />  
        <Route path="/ai-ml" element={<AIML />} />  
        <Route path="/compliance" element={<Compliance />} />  
        <Route path="/security" element={<Security />} />
        <Route path="/delivery-management" element={<DeliveryManagement />} />
        <Route path="/ui-ux-design" element={<UIUXDesign />} />
        <Route path="/devops" element={<DevOps />} />
        <Route path="/project-Management" element={<ProjectManagement />} />


        <Route path="/sagar-kapadia" element={<SagarKapadia/>} />
        <Route path="/sid-arora" element={<SidArora/>} />
        <Route path="/jaydeep-dosi" element={<JaydeepDosi/>} />
        <Route path="/sujeet-katiyar" element={<SujeetKatiyar/>} />
        <Route path="/dinesh-o-bareja" element={<DineshOBareja/>} />
        <Route path="/jigar-tewar" element={<JigarTewar/>} />
        <Route path="/pritesh-kacchadiya" element={<PriteshKacchadiya/> }/>
        <Route path="/purvik-rana" element={<PurvikRana/>} />
        <Route path="/dhaval-pancholi" element={<DhavalPancholi/>} />


      </Routes>
      <Footer />
    </div>
    </Router>
  );
}

export default App;
