import React from 'react';
import { Link } from 'react-router-dom';

import TeamImage from '../../assets/img/team/jigar-tewar.jpg';
import UIUXDesign  from '../services/ui-ux-design-page';

function JigarTewar() {
  return (
    <section className="space-extra-bottom">
                              <div className="project-inner-box mb-40">
                                <h3 className="box-title">Jigar Tewar – UI UX / Product Design Lead</h3>
                                
                                <div className="row gy-4 align-items-top">
                                    <div className="col-md-3">
                                        <img className="w-100 rounded-3" src={TeamImage} alt="project"/>
                                        <div className="th-social" style={{ paddingTop: '10px', textAlign: 'center' }}>
                                        <a target="_blank" href="http://www.linkedin.com/in/jigartewar"><i className="fab fa-linkedin-in"></i></a>&nbsp;&nbsp;&nbsp;
                                        <a target="_blank" href="mailto:jigar.tewar@cloudblaze.tech"><i className="fa-solid fa-envelope"></i></a>&nbsp;&nbsp;&nbsp;
                                            <Link to="../ui-ux-design"><i className="fa-solid fa-link"></i></Link>
                                    </div>
                                        
                                    
                                        
                                    </div>
                                    <div className="col-md-9">
                                        <div className="checklist">
                                            <ul>
                                                <li>Lead UX UI / Product Designer with over 12 years of experience in creating engaging and user-friendly digital experiences for various domains and platforms. His core competencies include user research, wireframing, prototyping, visual design, and design sprints. He has a passion for solving complex problems and delivering solutions that meet users' needs and business goals.<br/><br/>

                                                Strategic Thinking & Product Vision : Understands the business goals and user needs to define a clear product vision. Collaborates with product managers to define product roadmaps and prioritize features. Thinks holistically about the entire product experience, considering user needs, business objectives, and technical feasibility.<br/><br/>

                                                Design Thinking & User Research : Utilizes user research findings to inform design decisions. Employs design thinking methodology to create innovative and user-centered product solutions. Conducts user testing and gathers feedback to iterate on designs.<br/><br/>

                                                Usability Testing & Iteration : Conducts usability testing to evaluate design effectiveness and identify areas for improvement. Iterates on designs based on user feedback and testing results.
                                                
                                                Visual Design & Interaction Design : Creates visually appealing and functional user interfaces (UI) that are consistent with brand guidelines. Designs interactive elements and user flows to ensure a seamless and enjoyable user experience. Utilizes design software (Figma, Sketch) for wireframing, prototyping, and creating high-fidelity mockups.<br/><br/>

                                                Research Expertise : Conducts user research using various methodologies (surveys, interviews, usability testing) to understand user needs, behaviors, and pain points.<br/><br/>
                                                    
                                                Problem-solving & Critical Thinking : Asks insightful questions to uncover underlying user needs. Identifies usability issues and opportunities for improvement.
                                                
                                                </li>
                                                
                                                
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

   </section>
    );
}
export default JigarTewar;