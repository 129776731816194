import React from 'react';
import { Link } from 'react-router-dom';
import ServiceImage from '../../assets/img/service/delivery.jpg';
import TeamImage from '../../assets/img/team/purvik-rana.jpg';
import PurvikRana from '../team/purvik-rana';
function DeliveryManagement() {
  return (
    <section className="space-extra-bottom">
    <div className="container">
        <div className="row">
            <div className="col-xxl-12 col-lg-12">

                <div className="page-single">
                    <div className="page-img">
                        <img src={ServiceImage} alt="Service Image"/>
                    </div>
                    <div className="row gy-4 align-items-top">
                    <div className="page-content col-md-9">
                            <h2 className="h3 page-title">Delivery Management</h2>
                            <p>CloudBlaze Tech's business analysis services are designed to help clients identify their business needs and determine solutions to business problems. Our skilled business analysts work closely with stakeholders to gather and document requirements, conduct feasibility studies, and develop detailed business cases.</p>
                            
                            <p className="mb-30">We ensure that our solutions align with our clients' strategic goals, enhancing their operational efficiency and delivering measurable business value.</p>

    
                        
                        
                        <div className="col-md-3">
                                    <img className="w-100 rounded-3" src={TeamImage} alt="project"/>
                                    <div className="th-social" style={{ paddingTop: '10px', textAlign: 'center' }}>
                                    <a target="_blank" href="https://www.linkedin.com/in/purvikrana/"><i className="fab fa-linkedin-in"></i></a>&nbsp;&nbsp;&nbsp;
                                    <a target="_blank" href="mailto:purvik.rana@cloudblaze.tech"><i className="fa-solid fa-envelope"></i></a>&nbsp;&nbsp;&nbsp;
                                    <Link to="../purvik-rana"><i className="fa-solid fa-link"></i></Link>
                                    </div>
                                </div>
                        
                </div>
                </div>
        </div>
    </div>
</div>
</div>
</section>
  );
}


export default DeliveryManagement;
