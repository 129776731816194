// components/Footer.js
import React from 'react';
import Shape3 from '../assets/img/shape/footer_shape_3.png';
import Shape4 from '../assets/img/shape/footer_shape_4.png';
import Shape5 from '../assets/img/shape/footer_shape_5.png';
import Shape6 from '../assets/img/shape/footer_shape_6.png';


function Footer() {
  return (
    
    <footer className="footer-wrapper footer-layout2 bg-transparent">
    <div className="widget-area"/>
    <div className="copyright-wrap bg-theme">
        <div className="container">
            <div className="row justify-content-between align-items-center">
                <div className="col-lg-6">
                    <p className="copyright-text">Copyright <i className="fal fa-copyright"></i> 2024 <a href="https://www.jigartewar.in">Jigar Tewar</a>. All Rights Reserved.</p>
                </div>
                <div className="col-lg-6 text-end d-none d-lg-block">
                    <div className="footer-links">
                        <ul>
                            <li><a href="#">Terms & Condition</a></li>
                            <li><a href="#">Careers</a></li>
                            <li><a href="#">Privacy Policy</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="shape-mockup" data-top="0%" data-left="0%"><img src={Shape3} alt="shape"/></div>
    <div className="shape-mockup" data-top="0%" data-right="0%"><img src={Shape4} alt="shape"/></div>
    <div className="shape-mockup" data-bottom="0%" data-left="0%"><img src={Shape5} alt="shape"/></div>
    <div className="shape-mockup" data-bottom="0%" data-right="0%"><img src={Shape6} alt="shape"/></div>
</footer>


    
  );
}

export default Footer;
