import React from 'react';
import { Link } from 'react-router-dom';

import TeamImage from '../../assets/img/team/jaydeep-dosi.jpg';
import AIML  from '../services/ai-ml-page';

function JaydeepDosi() {
  return (
    <section className="space-extra-bottom">
                                 <div className="project-inner-box mb-40">
                                <h3 className="box-title">Jaydeep Dosi - AI, Data Science and Big Data</h3>
                                
                                <div className="row gy-4 align-items-top">
                                    <div className="col-md-3">
                                        <img className="w-100 rounded-3" src={TeamImage} alt="project"/>
                                        <div className="th-social" style={{ paddingTop: '10px', textAlign: 'center' }}>
                                        <a target="_blank" href="https://www.linkedin.com/in/jaydeepdosi"><i className="fab fa-linkedin-in"></i></a>&nbsp;&nbsp;&nbsp;
                                        <a target="_blank" href="mailto:jaydeep.dosi@cloudblaze.tech"><i className="fa-solid fa-envelope"></i></a>&nbsp;&nbsp;&nbsp;
                                            <Link to="../ai-ml"><i className="fa-solid fa-link"></i></Link>
                                    </div>

                                    </div>
                                    <div className="col-md-9">
                                        <div className="checklist">
                                            <ul>
                                                <li>Navigating the intricate tapestry of data engineering and AI/ML for nearly a decade, I've woven a rich legacy of innovation, transformation, and results. As the guiding force behind the presales and solutions brigade at Rishabh Software, a global torchbearer in software and tech services, my voyage is fueled by the ambition to metamorphose raw data into strategic masterpieces. My realm isn't just about numbers or algorithms; it's about orchestrating cloud magic and AI prowess to script success stories.<br/><br/>

                                                Donning the hat of AVP of Presales and Solutions, I champion the entire spectrum of data discovery. It's a dance from unearthing data gems to crafting compelling use cases, and ensuring they stand the test of feasibility. My sales mantra? A blend of consultation, customization, and commitment to addressing the individual narratives of our clientele. And the results speak for themselves - a stellar $5M+ sales footprint, an enviable 65% RFP conversion, and a heartwarming 67% client advocacy.<br/><br/>

                                                Among the milestones I cherish are crafting a predictive algorithm that skyrocketed a retailer's sales by 30%, devising a text analytics marvel that elevated satisfaction scores for a finance titan by 15%, and pioneering a data retrieval strategy that doubled the pace of care for a healthcare luminary.<br/><br/>

                                                In essence, I am not just a technologist but a storyteller, crafting sagas of transformation, innovation, and triumph. Let's collaborate and co-author the next big success chronicle!<br/><br/>

                                                
                                                
                                                </li>
                                                
                                                
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

        
   </section>
    );
}
export default JaydeepDosi;