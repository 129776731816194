// components/Process.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import SoftwareArchitecture from '../pages/services/software-architecture-page';
import ProductManagement from '../pages/services/product-management-page';
import AIML from '../pages/services/ai-ml-page';
import Navigation from './navigation';
function MobileMenu() {
  return (
    <div className="th-menu-wrapper">
    <div className="th-menu-area text-center">
        <button className="th-menu-toggle"><i className="fal fa-times"></i></button>
        <div className="mobile-logo">
            <a className="icon-masking" href="index.html"><img src="assets/img/CloudBlaze-logo@3x.png" alt="CloudBlaze Tech Solutions"/></a>
        </div>

        <div className="th-mobile-menu">
            <Navigation/>
        </div>
    </div>
</div>
  );
}

export default MobileMenu;
