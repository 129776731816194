// components/Process.jsx
import React from 'react';
import HeroImg from '../assets/img/hero/hero.png';
import { Link } from 'react-router-dom';

function Hero() {
  return (
    <section className="space" id="process-sec">
     <div className="th-hero-wrapper hero-1" id="hero">
        <div className="hero-img tilt-active">
            <img src={HeroImg} alt="Hero Image"/>
        </div>
        <div className="container">
            <div className="hero-style1">
                <span className="hero-subtitle">Providing Services For IT Transformation</span>
                
                <h1 className="hero-title">Vision</h1>
              <h1 className="hero-title">For IT <span className="text-theme fw-medium">Transformation</span></h1> 
              <p className="hero-text mb-10">To be a leading provider of innovative, scalable, and efficient SaaS solutions and software engineering consulting services, empowering businesses to achieve digital transformation and operational excellence globally. </p>
                <div className="btn-group">
                    <Link to="about-page" className="th-btn">ABOUT US<i className="fa-regular fa-arrow-right ms-2"></i></Link>
                </div>
            </div>
        </div>
    </div>
    
    </section>
  );
}

export default Hero;
