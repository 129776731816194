import React from 'react';
import serviceImage from '../../assets/img/service/architecture.jpg';
import teamImage from '../../assets/img/team/sagar-kapadia.jpg';
import SagarKapadia from '../team/sagar-kapadia';
import { Link } from 'react-router-dom';

function SoftwareArchitecture() {
  return (
<section className="space-extra-bottom">
<div className="container">
    <div className="row">
        <div className="col-xxl-12 col-lg-12">
            <div className="page-single">
                <div className="page-img">
                <img src={serviceImage} alt="Service Image"/>
                </div>
                 <div className="row gy-4 align-items-top">
                <div className="page-content col-md-9">
                    <h2 className="h3 page-title">Software Architecture</h2>
                    <p>Our architectural services focus on creating scalable, resilient, and flexible software architectures that support the long-term objectives of our clients. We specialize in designing microservices, serverless architectures, and cloud-native applications that optimize performance and ensure seamless integration with existing systems.</p>
                    
                    <p className="mb-30">Our architects work closely with clients to understand their business requirements and technology landscape, crafting solutions that are both innovative and practical.</p>

                    
                </div>
                
                     <div className="col-md-3">
                                <img className="w-100 rounded-3" src={teamImage} alt="project"/>
                                <div className="th-social" style={{ paddingTop: '10px', textAlign: 'center' }}>

                                <a target="_blank" href="http://www.linkedin.com/in/ks1974in"><i className="fab fa-linkedin-in"></i></a>&nbsp;&nbsp;&nbsp;
                                <a target="_blank" href="mailto:sagar.kapadia@cloudblaze.tech"><i className="fa-solid fa-envelope"></i></a>&nbsp;&nbsp;&nbsp;
                                    <Link to="../sagar-kapadia"><i className="fa-solid fa-link"></i></Link>
                            </div>
                                
                            
                                
                            </div>
                     
                </div>
            </div>
        </div>
    </div>
</div>
</section>
 );
}

export default SoftwareArchitecture;