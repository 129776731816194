// components/About.jsx
import React from 'react';
import heroImage from '../assets/img/hero/hero.png';
const AboutPage = () => {
    return (
        <div>
        <div className="overflow-hidden" id="about-sec">
            <div className="th-hero-wrapper hero-1" id="hero">
      <div className="hero-img tilt-active">
        <img src={heroImage} alt="Hero Image" />
      </div>
      <div className="container">
        <div className="hero-style1">
          <span className="hero-subtitle">Providing Services For IT Transformation</span>
          <h1 className="hero-title">Vision</h1>
          <p className="hero-text mb-10">
            To be a leading provider of innovative, scalable, and efficient SaaS solutions and software engineering consulting services, empowering businesses to achieve digital transformation and operational excellence globally.
          </p>
        </div>
      </div>
    </div>
        <div className="container">
            <div className="row align-items-left">
                <div className="col-xl-6">
                    <div className="ps-xxl-4 ms-xl-3 mt-50">
                        <div className="title-area mb-10">
                            <h1 className="hero-title">
                               Mission
                            </h1>
                            <h6 className="sec-title">Empowerment Through<span className="text-theme"> Technology</span></h6>
                        </div>
                        <p className="mt-n2 mb-25">Develop and deliver cutting-edge SaaS products that solve real-world business challenges, enhance productivity, and drive growth.</p>
                        
                        <div className="title-area mb-10">
                            <h6 className="sec-title">Excellence in <span className="text-theme">Consulting</span></h6>
                        </div>
                        <p className="mt-n2 mb-25">Provide expert software engineering consulting services that help clients optimize their processes, enhance their technological capabilities, and achieve their strategic goals.</p>
                        
                        <div className="title-area mb-10">
                            <h6 className="sec-title">Customer-Centric <span className="text-theme">Approach</span></h6>
                        </div>
                        <p className="mt-n2 mb-25">Maintain a relentless focus on understanding and exceeding customer expectations through continuous innovation, quality assurance, and outstanding support.</p>
                        
                        <div className="title-area mb-10">
                            <h6 className="sec-title">Sustainable <span className="text-theme">Growth</span></h6>
                        </div>
                        <p className="mt-n2 mb-25">Foster a culture of continuous improvement and sustainable growth, ensuring long-term value creation for stakeholders, employees, and the community.</p>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
    <div className="space" data-bg-src="assets/img/bg/why_bg_1.png">
        <div className="container">
            <div className="row align-items-right flex-row-reverse">
                
                 <div className="col-xxl-12 col-xl-12">
                     <h1 className="hero-title">Objectives</h1>
                    <div className="row mt-5 pt-10">
                <div className="col-xl-6 mb-xl-0">
                    

                    <h5 className="border-title">Product Development</h5>
                    <p className="mb-10">- Launch at least three innovative SaaS products within the first two years that address key pain points in target industries. </p>
                    <p className="mb-40">- Continuously improve and iterate on existing products based on customer feedback and market trends. </p>
                    
                     <h5 className="border-title">Quality and Performance</h5>
                    <p className="mb-10">- Ensure all SaaS products maintain a 99.9% uptime and meet high performance standards. </p>
                    <p className="mb-40">- Implement rigorous quality assurance processes to ensure the reliability and security of all solutions. </p>
                    
                     <h5 className="border-title">Consulting Excellence</h5>
                    <p className="mb-10">- Develop a team of highly skilled software engineering consultants with expertise in the latest technologies and methodologies. </p>
                    <p className="mb-40">- Successfully complete 100+ consulting projects in the first three years, with a customer satisfaction rate of over 95%. </p>
                    
                     <h5 className="border-title">Innovation and R&amp;D</h5>
                    <p className="mb-10">- Allocate 15% of annual revenue to research and development to stay ahead of technological advancements and market needs. </p>
                    <p className="mb-40">- Foster partnerships with academic institutions and technology providers to drive innovation and explore new opportunities. </p>
                    
                    
                </div>
                
                <div className="col-xl-6 mb-xl-0">
                    

                    <h5 className="border-title">Customer Engagement and Support</h5>
                    <p className="mb-10">- Establish a comprehensive customer support system offering 24/7 assistance and proactive solutions. </p>
                    <p className="mb-40">- Build a robust customer feedback loop to continuously improve products and services based on user insights. </p>
                    
                     <h5 className="border-title">Talent Development</h5>
                    <p className="mb-10">- Attract, retain, and develop top talent by offering a dynamic work environment, continuous learning opportunities, and career growth paths. </p>
                    <p className="mb-40">- Implement employee engagement programs to maintain high levels of motivation and productivity. </p>
                    
                     <h5 className="border-title">Sustainability and Social Responsibility</h5>
                    <p className="mb-10">- Adopt sustainable business practices to minimize environmental impact and promote social responsibility. </p>
                    <p className="mb-40">- Engage in community outreach programs and initiatives that support education, technology, and mental health awareness. </p>
                    
                     <h5 className="border-title">Partnerships and Alliances</h5>
                    <p className="mb-10">- Forge strategic partnerships with key industry players, technology vendors, and consulting firms to enhance product offerings and expand market reach. </p>
                    <p className="mb-40">- Leverage alliances to provide integrated solutions that deliver greater value to clients. </p>
                    
                    
                </div>
                     </div>
                
               
                </div>

            </div>
        </div>
    </div>
    </div>
    );
};

export default AboutPage;
