import React from 'react';
import { Link } from 'react-router-dom';
import AboutPage from '../pages/about-page';
import SoftwareArchitecture from '../pages/services/software-architecture-page';
import ProductManagement from '../pages/services/product-management-page';
import AIML from '../pages/services/ai-ml-page';
import Compliance from '../pages/services/compliance-page';
import Security from '../pages/services/security-page';
import UIUXDesign from '../pages/services/ui-ux-design-page';
import DevOps from '../pages/services/devops-page';
import Delivery from '../pages/services/delivery-management-page';
import ProjectManagement from '../pages/services/project-management-page';


import SagarKapadia from '../pages/team/sagar-kapadia';
import SidArora from '../pages/team/sid-arora';
import JaydeepDosi from '../pages/team/jaydeep-dosi';
import SujeetKatiyar from '../pages/team/sujeet-katiyar';
import DineshOBareja from '../pages/team/dinesh-o-bareja';
import JigarTewar from '../pages/team/jigar-tewar';
import PriteshKacchadiya from '../pages/team/pritesh-kacchadiya';
import PurvikRana from '../pages/team/purvik-rana';
import DhavalPancholi from '../pages/team/dhaval-pancholi';


function Navigation() {
    return (
  
<div>
<ul>
<li><Link to="Home">Home</Link></li>
<li><Link to="about-page">About Us</Link></li>
<li className="menu-item-has-children">
    <a href="#">Consulting Services</a>
    <ul className="sub-menu">
        <li><Link to="software-Architecture">Software Architecture</Link></li>
        <li><Link to="product-Management">Product Management</Link></li>
        <li><Link to="ai-ml">AI/ML</Link></li>  
        <li><Link to="compliance">Compliance</Link></li>
        <li><Link to="security">Security</Link></li>
        <li><Link to="ui-ux-design">UI/UX Design</Link></li>
        <li><Link to="devOps">DevOps</Link></li>
        <li><Link to="delivery-management">Delivery Management</Link></li>
        <li><Link to="project-management">Project Management</Link></li>
    </ul>
</li>
<li className="menu-item-has-children">
    <a href="#">Our Team</a>
    <ul className="sub-menu">
        <li><Link to="sagar-kapadia">Sagar Kapadia</Link></li>
        <li><Link to="sid-arora">Sid Arora</Link></li>
        <li><Link to="jaydeep-dosi">Jaydeep Dosi</Link></li>
        <li><Link to="sujeet-katiyar">Sujeet Katiyar</Link></li>
        <li><Link to="dinesh-o-bareja">Dinesh Bareja</Link></li>
        <li><Link to="jigar-tewar">Jigar Tewar</Link></li>
        <li><Link to="pritesh-kacchadiya">Pritesh Kacchadiya</Link></li>
        <li><Link to="purvik-rana">Purvik Rana</Link></li>
        <li><Link to="dhaval-pancholi">Dhaval Pancholi</Link></li>
    </ul>
</li>
<li>
    <Link to="contact.html">Contact</Link>
</li>
</ul>
</div>
    );
}
export default Navigation;