import React from 'react';
import { Link } from 'react-router-dom';

import TeamImage from '../../assets/img/team/purvik-rana.jpg';
import DeliveryManagement from '../services/delivery-management-page';  

function PurvikRana() {
  return (
    <section className="space-extra-bottom">
          <div className="project-inner-box mb-40">
                                <h3 className="box-title">Purvik Rana - Delivery Lead</h3>
                                
                                <div className="row gy-4 align-items-top">
                                    <div className="col-md-3">
                                        <img className="w-100 rounded-3" src={TeamImage} alt="project"/>
                                        <div className="th-social" style={{ paddingTop: '10px', textAlign: 'center' }}>
                                        <a target="_blank" href="https://www.linkedin.com/in/purvikrana/"><i className="fab fa-linkedin-in"></i></a>&nbsp;&nbsp;&nbsp;
                                        <a target="_blank" href="mailto:purvik.rana@cloudblaze.tech"><i className="fa-solid fa-envelope"></i></a>&nbsp;&nbsp;&nbsp;
                                            <Link to="../delivery-management"><i className="fa-solid fa-link"></i></Link>
                                    </div>
                                        
                                    
                                        
                                    </div>
                                    <div className="col-md-9">
                                        <div className="checklist">
                                            <ul>
                                                <li>🚀 Passionate IT Professional with 5+ Years of Diverse Experience
                                                I am a seasoned IT professional with over 5 years of hands-on experience, encompassing various roles that have honed my skills in project management, mobile application development and technical leadership.<br/><br/>

                                                👔 Associate Delivery Manager (1.5+ Years):
                                                In my recent role as an Associate Delivery Manager, I have successfully overseen project delivery, ensuring timelines, quality, and client satisfaction. My responsibilities included effective collaboration with cross-functional teams, resource allocation, and implementing best practices to meet project goals by following standard methodologies `Scrum` & `Kanban` as well as utilizing tools like JIRA, Trello, and Microsoft Excel.<br/><br/>

                                                🚀 Flutter Tech Lead:
                                                As a Flutter Tech Lead, I led a dynamic team in the adoption and implementation of Mobile application with Flutter farmework. My role involved providing technical guidance, driving the development process, and ensuring the delivery of cutting-edge applications with optimal performance and user experience.<br/><br/>

                                                📱 Mobile Application Developer (Android + Flutter):
                                                As a Mobile Application Developer, I have a strong foundation in designing and implementing best mobile solutions. I've contributed to the development of user-friendly and high-performance applications, staying abreast of the latest industry trends and technologies.<br/><br/>

                                                👩‍🏫 Teaching Experience (4.5+ Years):
                                                In addition to my industry experience, I have dedicated over 4.5+ years to teaching, where I've had the privilege of imparting knowledge in CE and fostering the growth of aspiring engineers. My teaching experience has enhanced my ability to communicate complex concepts effectively and tailor learning experiences to diverse students.<br/><br/>

                                                🚀 What Sets Me Apart:
                                                - Proven track record in project management and delivery.
                                                - In-depth knowledge of mobile application development, with expertise in Flutter.
                                                - Effective leadership skills with a focus on team collaboration and professional growth.
                                                - Passion for staying ahead of industry trends and adopting innovative technologies.
                                                - Extensive teaching experience, contributing to the development of future IT engineers.<br/><br/>

                                                

                                                
                                                
                                                </li>
                                                
                                                
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
   </section>
    );
}
export default PurvikRana;