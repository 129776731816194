import React from 'react';
import { Link } from 'react-router-dom';

import TeamImage from '../../assets/img/team/pritesh-kachhadiya.jpg';
import ProjectManagement  from '../services/project-management-page';

function PriteshKacchadiya() {
  return (
    <section className="space-extra-bottom">
     <div className="project-inner-box mb-40">
                                <h3 className="box-title">Pritesh Kacchadiya - Software Engineering Project Management &nbsp; Software Development</h3>
                                
                                <div className="row gy-4 align-items-top">
                                    <div className="col-md-3">
                                        <img className="w-100 rounded-3" src={TeamImage} alt="project"/>
                                        <div className="th-social" style={{ paddingTop: '10px', textAlign: 'center' }}>
                                        <a target="_blank" href="https://www.linkedin.com/in/priteshkachhadiya/"><i className="fab fa-linkedin-in"></i></a>&nbsp;&nbsp;&nbsp;
                                        <a target="_blank" href="mailto:pritesh.kacchadiya@cloudblaze.tech"><i className="fa-solid fa-envelope"></i></a>&nbsp;&nbsp;&nbsp;
                                        <Link to="../project-management"><i className="fa-solid fa-link"></i></Link>
                                    </div>
                                        
                                    
                                        
                                    </div>
                                    <div className="col-md-9">
                                        <div className="checklist">
                                            <ul>
                                                <li>I bring a wealth of expertise in the information technology and services industry.<br/><br/>
                                                    
                                                Our team is dedicated to crafting high-quality, bespoke software solutions tailored to address our clients' unique business requirements. Whether it's the development of a custom mobile app or the implementation of a sophisticated web application, we possess the proficiency and resources to transform your vision into reality.<br/><br/>

                                                With a Bachelor of Engineering (B.E.) degree in Information Technology from South Gujarat University, I bring a robust background in both technology and business development to the table. My passion lies in harnessing the power of technology to overcome intricate business challenges, and I am steadfast in my commitment to aiding clients in achieving their objectives through innovative software solutions.<br/><br/>
                                                    
                                                Should you seek a dependable partner to spearhead your software development endeavors, I invite you to reach out. I am eager to engage in a discussion regarding your requirements and explore how we can contribute to the realization of your business objectives.

                                                </li>
                                                
                                                
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>


   </section>
    );
}
export default PriteshKacchadiya;