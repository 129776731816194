import React from 'react';
import { Link } from 'react-router-dom';

import TeamImage from '../../assets/img/team/sagar-kapadia.jpg';
import SoftwareArchitecture  from '../services/software-architecture-page';

function SagarKapadia() {
  return (
    <section className="space-extra-bottom">
         <div className="project-inner-box mb-40">
                <h3 className="box-title">Sagar Kapadia – Solution Architect, and Engineering Manager [CEO]</h3>
                <div className="row gy-4 align-items-top">
                    <div className="col-md-3">
                        <img className="w-100 rounded-3" src={TeamImage} alt="project"/>
                        <div className="th-social" style={{ paddingTop: '10px', textAlign: 'center' }}>
                        <a target="_blank" href="http://www.linkedin.com/in/ks1974in"><i className="fab fa-linkedin-in"></i></a>&nbsp;&nbsp;&nbsp;
                        <a target="_blank" href="mailto:sagar.kapadia@cloudblaze.tech"><i className="fa-solid fa-envelope"></i></a>&nbsp;&nbsp;&nbsp;
                        <Link to="../software-architecture"><i className="fa-solid fa-link"></i></Link>
                    </div>
                </div>
                <div className="col-md-9">
                        <div className="checklist">
                            <ul>

                                <li>
                                With over 13 years of comprehensive experience in software engineering, Sagar R. Kapadia has established himself as a proficient leader in software architecture, system design, and agile project management. He holds key certifications in Software Architecture from ISAQB-F, and Service Oriented Architecture from SEI CMU, along with certifications as a ScrumMaster and Scrum Product Owner from the Scrum Alliance. <br/><br/>

                                Sagar's educational background includes a PGDITM with a major in Project Management from Welingkar Institute, a Diploma in Advanced Computing from C-DAC Mumbai, and a Bachelor's degree in Organic Chemistry from P.T Sarvajanik College of Science, Surat. He has intermediate qualifications in CA and ICWAI, focusing on Accounting and Financial Management. <br/><br/>

                                Professionally, Sagar has spearheaded multiple high-stakes projects across various domains, demonstrating his ability in leading complex projects from concept to completion. His expertise spans a broad range of technologies including Java EE, Node.js, .NET frameworks, and advanced DevOps practices. He has been pivotal in roles that require a deep understanding of cloud microservices, front-end technologies like Angular, and comprehensive backend systems. <br/><br/>

                                At Cloudblaze Tech Solutions, Sagar is instrumental in driving innovation and strategic growth, emphasizing the creation of scalable solutions that address real-world challenges. His passion for building and creating, coupled with a drive to continually learn and evolve professionally, positions him as a dynamic leader in the tech industry.</li>
                            </ul>
                        </div>
                </div>
            </div>
        </div>
   </section>
    );
}
export default SagarKapadia;