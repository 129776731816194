import React from 'react';
import ServiceImage from '../../assets/img/service/product-management.jpg';
import TeamImage from '../../assets/img/team/sid-arora.jpg';
import { Link } from 'react-router-dom';
import PurvikRana from '../team/purvik-rana';

function ProductManagement() {
  return (
    <section className="space-extra-bottom">
    <div className="container">
        <div className="row">
            <div className="col-xxl-12 col-lg-12">

                <div className="page-single">
                    <div className="page-img">
                        <img src={ServiceImage} alt="Service Image"/>
                    </div>
                    <div className="row gy-4 align-items-top">
                    <div className="page-content col-md-9">
                        <h2 className="h3 page-title">Product Management</h2>
                        <p>Our product management services focus on guiding products from conception through to launch and beyond. We adopt a holistic approach that includes market research, product strategy, roadmap planning, and lifecycle management.</p>
                        
                        <p className="mb-30">Our product managers work collaboratively with cross-functional teams to ensure that the products we develop meet market needs, achieve business objectives, and deliver exceptional user experiences. We help our clients to innovate, stay competitive, and maximize their return on investment.</p>

                        
                    </div>
                        
                        
                        <div className="col-md-3">
                                    <img className="w-100 rounded-3" src={TeamImage} alt="project"/>
                                    <div className="th-social" style={{ paddingTop: '10px', textAlign: 'center' }}>
                                    <a target="_blank" href="https://www.linkedin.com/in/siddhartharoraisb/"><i className="fab fa-linkedin-in"></i></a>&nbsp;&nbsp;&nbsp;
                                    <a target="_blank" href="mailto:siddharth.arora@cloudblaze.tech"><i className="fa-solid fa-envelope"></i></a>&nbsp;&nbsp;&nbsp;
                                    <Link to="../purvik-rana"><i className="fa-solid fa-link"></i></Link>
                                </div>
                                    
                                
                                    
                                </div>
                        
                </div>
                </div>
        </div>
    </div>
</div>
</section>
  );
}


export default ProductManagement;
