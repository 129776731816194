import React from 'react';
import { Link } from 'react-router-dom';
import ServiceImage from '../../assets/img/service/project-management.jpg';
import TeamImage from '../../assets/img/team/pritesh-kachhadiya.jpg';
import PriteshKacchadiya  from '../team/pritesh-kacchadiya';
function ProjectManagement() {
  return (
    <section className="space-extra-bottom">
    <div className="container">
        <div className="row">
            <div className="col-xxl-12 col-lg-12">

                <div className="page-single">
                    <div className="page-img">
                        <img src={ServiceImage} alt="Service Image"/>
                    </div>
                    <div className="row gy-4 align-items-top">
                    <div className="page-content col-md-9">
                    <h2 className="h3 page-title">Project Management</h2>
                            <p>CloudBlaze Tech offers comprehensive project management services that encompass the entire software development lifecycle. From initial concept and design to development, testing, and deployment, our team of experienced engineers ensures the creation of robust, scalable, and high-performance software solutions tailored to meet the unique needs of our clients.</p>
                            
                            <p className="mb-30">Our expertise spans a variety of programming languages, frameworks, and platforms, enabling us to deliver cutting-edge applications that drive business growth and efficiency.</p>

                            
                        
                    </div>
                        
                        
                        <div className="col-md-3">
                                    <img className="w-100 rounded-3" src={TeamImage} alt="project"/>
                                    <div className="th-social" style={{ paddingTop: '10px', textAlign: 'center' }}>
                                    <a target="_blank" href="https://www.linkedin.com/in/priteshkachhadiya/"><i className="fab fa-linkedin-in"></i></a>&nbsp;&nbsp;&nbsp;
                                        <a target="_blank" href="mailto:pritesh.kacchadiya@cloudblaze.tech"><i className="fa-solid fa-envelope"></i></a>&nbsp;&nbsp;&nbsp;
                                            <Link to="../pritesh-kacchadiya"><i className="fa-solid fa-link"></i></Link>
                                    </div>
                                        
                                    
                                
                                    
                                </div>
                        
                </div>
                </div>
        </div>
    </div>
</div>
</section>
  );
}


export default ProjectManagement;
