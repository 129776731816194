// components/Process.jsx
import React from 'react';
import Services from '../components/services';
import Hero from '../components/hero';

function HomePage() {
  return (
    <section className="space" id="home-sec">
     <Hero />
     <Services/>
    </section>
  );
}

export default HomePage;
